import React from 'react';
import { Fade } from 'react-reveal';
import Image from '../components/Image';
import UAsample from '../images/ua-sample1.png'
import GMVideo from '../videos/GM.mp4';


const CakeAppsContent = () => { 
  return (
  <div id={'main-content'}>           
    <section className={'project-detail-hero'} >
      <h1 className={'project-name two-piece-combo'} >
        <span className={'slide-top a1'}>CAKE </span> 
        <span className={'slide-top a2'}>Director &amp;</span>
        <span className={'slide-top a2'}>App Design</span>  
      </h1>
    <Fade >
      <div className={'contribution two-piece-combo'} >
        <div className={'icon'} >&#xe908;</div>
        <ul>
          <li>Design Team Management</li>
          <li>Product &amp; Process Design</li>
          <li>Quantitative Research</li>
        </ul>        
      </div>
    </Fade>
    </section>

    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          Player-Coach
        </h2>
        <Fade fraction={.71}>
          <Image
            filename="cake-team.jpg"
            alt="CAKE team"
            className='cake-team'
          />
        </Fade>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          As the Director of Design &amp; User Experience at CAKE, my main focus was growing and assisting the Design team. Through my tenure I influenced change in the product management process to include user research and analytics in product decisions. Alongside a team of up to 4 product designers and 2 user researchers, we fulfilled the brand promise of making a restaurant operator’s job easier through clean utilitarian design.
        </p><p>
          In addition to managing and staffing, I was also responsible for designing and redesigning products.
        </p>

      </div>
    </section>
    </Fade>

    <Fade>
    <section className={'project-info inbed'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          Guest Manager
        </h2>
        <div className={'gm-components'}>
          <h3>Table Components:</h3>
          <ul>
            <li>
              <Image
                filename="table-sample-1.png"
                alt="Guest Manager Table Component"
                className='gm-table'
              />
              <p className={'caption'}>
                Unassigned
              </p>
            </li>
            <li>
              <Image
                filename="table-sample-2.png"
                alt="Guest Manager Table Component"
                className='gm-table'
              />
              <p className={'caption'}>
                Server Assigned
              </p>
            </li>
            <li>
              <Image
                filename="table-sample-3.png"
                alt="Guest Manager Table Component"
                className='gm-table'
              />
              <p className={'caption'}>
                Seated
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          Guest Manager is an iPad app that allows a restaurant host to manage their waitlist, including the ability to text a customer when a table is ready or communicate a curbside pickup. Alongside a talented product manager and a user researcher, we relaunched the product with a smoother experience and fresh interface. 
        </p><p>
          The icing on this CAKE was a redesign of the flows &amp; screens.
        </p>
      </div>
    </section>
    </Fade>
    <section className={'project-info gm-carousel'}>
      <video 
        poster={''}
        muted 
        preload 
        autoPlay 
        loop 
        muted 
        playsInline
      >
        <source src={GMVideo} type="video/mp4" />
      </video>
    </section>

    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          Universal Admin
        </h2>
        <div className={'ua-ia'}>
          <h3>High-level Information Architecture 
            <span>Ordered by Frequency of Use</span>
          </h3>
          <ul>
            <li>Sales</li>
            <li className={'line'}></li>
            <li>Customers</li>
            <li className={'line'}></li>
            <li>Staff</li>
            <li className={'line'}></li>
            <li>Menu</li>
            <li className={'line'}></li>
            <li>Settings</li>
          </ul>
        </div>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          Early in my tenure I noticed that a typical CAKE customer needed to log in to several different CAKE based sites to set up and manage their restaurant tools. This was an obvious challenge for our customers, and a growing challenge to manage multiple admin products. I took the initiative to pitch a single site to administer our tools.

        </p><p>
          This was a huge information architecture challenge with many stakeholders. I established an organizational strategy of separating sections by the different operations of a restaurant, proritized by frequency of use. 
        </p><p>
          Nearly every product designer and user researcher played a hand at building the Universal Admin CMS.
        </p>
      </div>
    </section>
    </Fade>
    <section className={'project-info inbed ua'}>
      <img 
          alt="Sample of the Universal Admin Redesign" 
          className={'ua-sample'} 
          src={UAsample} 
        />
    </section>
    <p className={'caption ua'}>
        (Private content / blurred by design. Contact for more info)
      </p>
  </div>
  )
}

export default CakeAppsContent;